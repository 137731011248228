import { Component, OnInit } from '@angular/core';
import { ClientService } from 'src/app/services/client.service';
import { ActivatedRoute,Router  } from '@angular/router';

import { FormGroup, FormControl, Validators, FormBuilder,ReactiveFormsModule } from '@angular/forms';

import {Subject, Observable} from 'rxjs';
import {WebcamImage, WebcamInitError, WebcamUtil} from 'ngx-webcam'; 
import Swal from 'sweetalert2'

@Component({
  selector: 'app-edit-client',
  templateUrl: './edit-client.component.html',
  styleUrls: ['./edit-client.component.css']
})
export class EditClientComponent implements OnInit {

  clientForm: FormGroup; 
  
  submitted = false;
  
  public showWebcam = true;
  public allowCameraSwitch = true;
  public multipleWebcamsAvailable = false;
  public deviceId: string;
  public videoOptions: MediaTrackConstraints = {
    // width: {ideal: 1024},
    // height: {ideal: 576}
  };
  public errors: WebcamInitError[] = [];
  public webcamImage: WebcamImage = null;
  private trigger: Subject<void> = new Subject<void>();
  private nextWebcam: Subject<boolean|string> = new Subject<boolean|string>();

  public documentUploadType = 1;

  public displayStyle = "none";

  public backDisplayStyle = "none";

  public camSide = 0;

  public imageAsDataUrl = "";

  public backimageAsDataUrl = "";

  public assetUrl = 'https://nodejspro.btcbazaar.ca/images/';

  constructor(private clientService: ClientService,private route: ActivatedRoute,private router: Router,private fb: FormBuilder) { 
      
      

  }

  ngOnInit() {

    WebcamUtil.getAvailableVideoInputs()
      .then((mediaDevices: MediaDeviceInfo[]) => {
        this.multipleWebcamsAvailable = mediaDevices && mediaDevices.length > 1;
      });

    this.clientForm = this.fb.group({ 
        first_name: ["", Validators.required], 
        last_name: ["", Validators.required], 
        middle_name: [""], 
        company_name: [""], 
        address: ["", Validators.required], 
        phone_number: ["", Validators.required], 
        email_address: ["", [Validators.required, Validators.minLength(5), Validators.email]], 
        occupation: ["", Validators.required], 
        date_of_verification: ["", Validators.required], 
        type_of_document: ["", Validators.required], 
        fileSource: [""],
        backFileSource: [""],
        document_upload_type: ["1"],
        document_number: ["", Validators.required], 
        expire_date: ["", Validators.required], 
        in_person: ["", Validators.required], 
        file_store_path: ["", Validators.required], 
        notes: ["", Validators.required], 
        file: [''],
        backfile: [''],
    }); 

    this.getClient(this.route.snapshot.paramMap.get('id'));

    
  
  }


  getClient(id) {
    this.clientService.get(id)
      .subscribe(
        (data:any) => {
          // this.currentTutorial = data;
          if(data)
          {
              if(data.document_upload_type == 2)
              {
                this.documentUploadType = data.document_upload_type;
                this.imageAsDataUrl = this.assetUrl+data.front_document_pic_name;	
                this.backimageAsDataUrl = this.assetUrl+data.back_document_pic_name;	
              }
              var setValues = {
          
                first_name: data.first_name, 
                last_name: data.last_name,  
                middle_name: data.middle_name,
                company_name: data.company_name,
                address: data.address, 
                phone_number: data.phone_number, 
                email_address: data.email_address, 
                occupation: data.occupation, 
                date_of_verification: data.date_of_verification, 
                type_of_document:data.type_of_document, 
                fileSource: "",
                backFileSource: "",
                document_upload_type:(data.document_upload_type) ? data.document_upload_type : 1,
                document_number: data.document_number, 
                expire_date: data.expire_date, 
                in_person: data.in_person, 
                file_store_path:data.file_store_path, 
                notes: data.notes,
                file: '',
                backfile: '', 
            };
            this.clientForm.setValue(setValues);
          }
        },
        error => {
          console.log(error);
        });
  }


  onFileChange(event) {
  
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.clientForm.patchValue({
        fileSource: file
      });
    }
  }
  
  backOnFileChange(event) {
  
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      console.log(file);
      this.clientForm.patchValue({
        backFileSource: file
      });
    }
  }
   
 
 saveClient(data) {
   console.log(data);
   console.log(this.clientForm);
    this.submitted = true;
    if( this.clientForm.valid) {
       
        const formData = new FormData();
        formData.append('first_name', this.clientForm.get('first_name').value);
        formData.append('last_name', this.clientForm.get('last_name').value);
        formData.append('middle_name', this.clientForm.get('middle_name').value);
        formData.append('company_name', this.clientForm.get('company_name').value);
        formData.append('address', this.clientForm.get('address').value);
        formData.append('phone_number', this.clientForm.get('phone_number').value);
        formData.append('email_address', this.clientForm.get('email_address').value);
        formData.append('occupation', this.clientForm.get('occupation').value);
        formData.append('date_of_verification', this.clientForm.get('date_of_verification').value);
        formData.append('type_of_document', this.clientForm.get('type_of_document').value);
        formData.append('document_number', this.clientForm.get('document_number').value);
        formData.append('document_upload_type', this.clientForm.get('document_upload_type').value);
        formData.append('expire_date', this.clientForm.get('expire_date').value);
        formData.append('in_person', this.clientForm.get('in_person').value);
        formData.append('file_store_path', this.clientForm.get('file_store_path').value);
        formData.append('notes', this.clientForm.get('notes').value);
        formData.append('file', this.clientForm.get('fileSource').value);
        formData.append('backfile', this.clientForm.get('backFileSource').value);
        console.log(formData);
        this.clientService.update(this.route.snapshot.paramMap.get('id'),formData)
          .subscribe(
            response => {
              console.log(response);
              Swal.fire(
                'Success!',
                'Client successfully created!',
                'success'
              ).then((result) => {
                this.router.navigate(['/']);
                this.submitted = true;
              });
            },
            error => {
              console.log(error);
            });
    }
  }


  public triggerSnapshot(): void {
    this.trigger.next();
  }

  public toggleWebcam(): void {
    this.showWebcam = !this.showWebcam;
  }

  public handleInitError(error: WebcamInitError): void {
    this.errors.push(error);
  }

  public showNextWebcam(directionOrDeviceId: boolean|string): void {
    this.nextWebcam.next(directionOrDeviceId);
  }

  dataURItoBlob(dataURI) {
      const byteString = window.atob(dataURI);
      const arrayBuffer = new ArrayBuffer(byteString.length);
      const int8Array = new Uint8Array(arrayBuffer);
      for (let i = 0; i < byteString.length; i++) {
        int8Array[i] = byteString.charCodeAt(i);
      }
      const blob = new Blob([int8Array], { type: 'image/png' });    
      return blob;
  } 

  
  
  public handleImage(webcamImage: WebcamImage): void {
    console.info('received webcam image', webcamImage);
    
   /*  this.clientForm.patchValue({
      fileSource: webcamImage.imageAsDataUrl
    }); */

    if(this.camSide == 1)
    {   
      this.backimageAsDataUrl = webcamImage.imageAsDataUrl;
      this.webcamImage = webcamImage;
      this.backDisplayStyle = "none";
      
      const imageName = 'name.png';
      const imageBlob = this.dataURItoBlob(webcamImage.imageAsDataUrl.split(',')[1]);
      const imageFile = new File([imageBlob], imageName, { type: 'image/png' });
      this.clientForm.patchValue({
        backFileSource: imageFile
      });
    }
    else 
    {
      this.imageAsDataUrl = webcamImage.imageAsDataUrl;
      this.webcamImage = webcamImage;
      this.displayStyle = "none";
      
      const imageName = 'name.png';
      const imageBlob = this.dataURItoBlob(webcamImage.imageAsDataUrl.split(',')[1]);
      const imageFile = new File([imageBlob], imageName, { type: 'image/png' });
      this.clientForm.patchValue({
        fileSource: imageFile
      });
    }
  }

  public cameraWasSwitched(deviceId: string): void {
    console.log('active device: ' + deviceId);
    this.deviceId = deviceId;
  }

  public get triggerObservable(): Observable<void> {
    return this.trigger.asObservable();
  }

  public get nextWebcamObservable(): Observable<boolean|string> {
    return this.nextWebcam.asObservable();
  }

  public documentTypeChange(d) {
    console.log(d.target.value);
    this.documentUploadType = d.target.value;
  }

  public openPopup() {
    this.camSide = 0;
    this.displayStyle = "block";
  }

  public closePopup() {
    this.displayStyle = "none";
  }
  
  public backclosePopup() {
    this.backDisplayStyle = "none";
  }
  
  public backOpenPopup() {
    this.camSide = 1;
    this.backDisplayStyle = "block";
  }

  public backClosePopup() {
    this.backDisplayStyle = "none";
  }

}
 