import { Component, OnInit } from '@angular/core';
import { TutorialService } from 'src/app/services/tutorial.service';
import { TransactionHistoryService } from 'src/app/services/transaction_history.service';

@Component({
  selector: 'app-transactionhistory',
  templateUrl: './transactionhistory.component.html',
  styleUrls: ['./transactionhistory.component.css']
})
export class TransactionHistoryComponent implements OnInit {

  tutorials: any;
  transactionhistories: any;
  currentTutorial = null;
  currentIndex = -1;
  title = '';
  currentPage = 1;

  constructor(private transactionHistoryService: TransactionHistoryService,private tutorialService: TutorialService) { 

  }

  ngOnInit() {
    this.retrieveTransactionHistory(this.currentPage);
  }

  retrieveTransactionHistory(currentPage) {
    this.transactionHistoryService.getAll(currentPage,0)
      .subscribe(
        (data:any) => {
          this.transactionhistories = data;
          console.log(data);
        },
        error => {
          console.log(error);
        });
  }

  refreshList() {
    this.retrieveTransactionHistory(1);
    this.currentTutorial = null;
    this.currentIndex = -1;
  }

  setActiveTutorial(tutorial, index) {
    this.currentTutorial = tutorial;
    this.currentIndex = index;
  }

  removeAllTutorials() {
    this.tutorialService.deleteAll()
      .subscribe(
        response => {
          console.log(response);
          this.refreshList();
        },
        error => {
          console.log(error);
        });
  }

  searchTitle() {
    this.tutorialService.findByTitle(this.title)
      .subscribe(
        data => {
          this.tutorials = data;
          console.log(data);
        },
        error => {
          console.log(error);
        });
  }

  createRange(number){
    // var items: number[] = [];
    // for(var i = 1; i <= number; i++){
    //   items.push(i);
    // }
    // return items;
    return new Array(number);
  }
}
