import { Component, OnInit } from '@angular/core';
import { ClientService } from 'src/app/services/client.service';
import { TransactionHistoryService } from 'src/app/services/transaction_history.service';
import { Router  } from '@angular/router';
import { FormGroup, FormControl, Validators, FormBuilder,ReactiveFormsModule } from '@angular/forms';
import Swal from 'sweetalert2'

@Component({
  selector: 'app-buy',
  templateUrl: './buy.component.html',
  styleUrls: ['./buy.component.css']
})
export class BuyComponent implements OnInit {

  buyForm: FormGroup; 
  submitted = false;
  clients: any[];
  purchase_histories: any;
  payment_types: any;
  exchange_uses: any;
  currentTutorial = null;
  currentSelectedCurrency = '';
  currentIndex = -1;
  title = '';
  public displayStyle = "none";
  output = '';
  
  keyword = 'full_name';
  dataT = [
     {
       id: 1,
       name: 'Usa'
     },
     {
       id: 2,
       name: 'England'
     }
  ];
  constructor(private transactionHistoryService: TransactionHistoryService,private clientService: ClientService,private router: Router,private fb: FormBuilder) { 
      this.purchase_histories = [];
      this.clients = [];
  }

  selectEvent(item) {
     
    this.buyForm.controls['client_id'].setValue(item.id);
    // do something with selected item
  }
 
  onChangeSearch(val: string) {
    // fetch remote data from here
    // And reassign the 'data' which is binded to 'data' property.
  }
  
  onFocused(e){
    // do something when input is focused
  }
  
  purchaseCurrency(e){
    
    this.purchase_histories.forEach((val,key)=>{
      if(val.id == e.target.value)
      {
        this.currentSelectedCurrency = val.name;
      } 
    });
    console.log(e.target.value);
  }
  
  ngOnInit() {
    this.retrieveClients(); 

    this.buyForm = this.fb.group({ 
        invoice_number: ["", Validators.required], 
        client_id: ["", Validators.required], 
        client_name: ["", Validators.required], 
        wallet_address: ["", Validators.required], 
        date_of_purchase: [new Date(), Validators.required], 
        type: ["buy", Validators.required], 
        amount_in_cad: ["", Validators.required], 
        purchasing_currency_id: ["", Validators.required], 
        live_rate: ["", Validators.required], 
        available_crypto_amount: ["", Validators.required], 
        fx_rate: ["", Validators.required], 
        fee_percentage: ["", Validators.required], 
        fee_gained: ["", Validators.required],
        amount_usdt: ["", Validators.required],
        amount: ["", Validators.required],
        trading_fee: ["", Validators.required], 
        network_fee	: ["", Validators.required], 
        payment_type_id: ["", Validators.required], 
        exchange_use: ["", Validators.required], 
        payout_amount: ["", Validators.required], 
        payout_unit: ["", Validators.required], 
        other_fees: ["", Validators.required], 
    }); 
  }

   

  retrieveClients() {
    this.transactionHistoryService.getLastTransactionHistory()
      .subscribe(
        (data:any) => {
          this.buyForm.controls['invoice_number'].setValue(10000+data.data+1);

          // console.log(data);
        },
        error => {
          console.log(error);
        });
    
    this.clientService.getAll(1,1)
      .subscribe(
        (data:any) => {
          var clientData = [];
          data.data.forEach((val,key)=>{
            val.full_name = val.first_name+" "+val.last_name;
            clientData.push(val)
          });
          this.clients = clientData;
          // console.log(this.clients);
        },
        error => {
          console.log(error);
        });
    this.clientService.getAllPurchaseHistory()
      .subscribe(
        (data:any) => {
          this.purchase_histories = data;
          // console.log(data);
        },
        error => {
          console.log(error);
        });
    this.clientService.getAllPaymentType()
      .subscribe(
        (data:any) => {
          this.payment_types = data;
          // console.log(data);
        },
        error => {
          console.log(error);
        });
    this.clientService.getAllExchangeUse()
      .subscribe(
        (data:any) => {
          this.exchange_uses = data;
          // console.log(data);
        },
        error => {
          console.log(error);
        });
    
  }

  refreshList() {
    this.retrieveClients();
    this.currentTutorial = null;
    this.currentIndex = -1;
  }

  public openPopup() {
    this.displayStyle = "block";
  }

  public closePopup() {
    this.displayStyle = "none";
  }
   
  public qrcodeDetucted($event)
  {
    this.output = $event;
    this.buyForm.controls['wallet_address'].setValue(this.output);
    
    this.closePopup();
  } 

  
  saveTransaction(data) {
    this.submitted = true;
    console.log(this.buyForm.valid);
    console.log(this.buyForm);
    if( this.buyForm.valid) {
      let client = {
        invoice_number: this.buyForm.get('invoice_number').value,
        client_id: this.buyForm.get('client_id').value,
        wallet_address: this.buyForm.get('wallet_address').value,
        date_of_purchase: this.buyForm.get('date_of_purchase').value,
        type: this.buyForm.get('type').value,
        amount_in_cad: this.buyForm.get('amount_in_cad').value,
        available_crypto_amount: this.buyForm.get('available_crypto_amount').value,
        purchasing_currency_id: this.buyForm.get('purchasing_currency_id').value,
        live_rate: this.buyForm.get('live_rate').value,
        fx_rate: this.buyForm.get('fx_rate').value,
        fee_percentage: this.buyForm.get('fee_percentage').value,
        fee_gained: this.buyForm.get('fee_gained').value,
        amount_usdt: this.buyForm.get('amount_usdt').value,
        amount: this.buyForm.get('amount').value,
        trading_fee: this.buyForm.get('trading_fee').value,
        network_fee: this.buyForm.get('network_fee').value,
        payment_type_id: this.buyForm.get('payment_type_id').value,
        exchange_use: this.buyForm.get('exchange_use').value,
        payout_amount: this.buyForm.get('payout_amount').value,
        payout_unit: this.buyForm.get('payout_unit').value,
        other_fees: this.buyForm.get('other_fees').value,
      }; 
      
         
      console.log(client);
      this.transactionHistoryService.create(client)
        .subscribe(
          response => {
            console.log(response);
            // alert("Transaction successfully created");
            Swal.fire(
              'Success!',
              'Transaction successfully created!',
              'success'
            ).then((result) => {
              this.router.navigate(['/transactionhistory']);
              this.submitted = true;
            });
           
          },
          error => {
            console.log(error);
          });
    }
  }
  
  onlyNumberKey(evt) {
            
      // Only ASCII character in that range allowed
      var ASCIICode = (evt.which) ? evt.which : evt.keyCode
      if (ASCIICode > 31 && (ASCIICode < 48 || ASCIICode > 57))
          return false;
      return true;
  }

  keyPressNumbersDecimal(event) {
    var charCode = (event.which) ? event.which : event.keyCode;
    if (charCode != 46 && charCode > 31
      && (charCode < 48 || charCode > 57)) {
      event.preventDefault();
      return false;
    }
    return true;
  }

  checkFeeGained() {
    if(this.buyForm.get('amount_in_cad').value != "" && this.buyForm.get('fee_percentage').value != "")
    {
      var fee_gained = (this.buyForm.get('amount_in_cad').value*this.buyForm.get('fee_percentage').value)/100;
      this.buyForm.get('fee_gained').setValue(fee_gained);
      this.buyForm.get('amount_usdt').setValue(this.buyForm.get('amount_in_cad').value-fee_gained);
    }
    if(this.buyForm.get('amount_in_cad').value != "" && this.buyForm.get('fee_percentage').value != "" && this.buyForm.get('fx_rate').value != "")
    {
      console.log(this.buyForm.get('fx_rate').value);
      var fee_gained = (this.buyForm.get('amount_in_cad').value*this.buyForm.get('fee_percentage').value)/100;
      this.buyForm.get('amount_usdt').setValue((this.buyForm.get('amount_in_cad').value-fee_gained)/this.buyForm.get('fx_rate').value);
    }

    if(this.buyForm.get('amount_in_cad').value != "" && this.buyForm.get('fee_percentage').value != "" && this.buyForm.get('fx_rate').value != "" && this.buyForm.get('live_rate').value != "")
    {
      console.log(this.buyForm.get('fx_rate').value);
      var fee_gained = (this.buyForm.get('amount_in_cad').value*this.buyForm.get('fee_percentage').value)/100;
      var amount_usdt = (this.buyForm.get('amount_in_cad').value-fee_gained)/this.buyForm.get('fx_rate').value;
      this.buyForm.get('trading_fee').setValue(amount_usdt / this.buyForm.get('live_rate').value);
    }
    if(this.buyForm.get('trading_fee').value != "" && this.buyForm.get('network_fee').value != "")
    {
      var other_fees = (this.buyForm.get('trading_fee').value+this.buyForm.get('network_fee').value);
      this.buyForm.get('other_fees').setValue(other_fees);
    }
  }
}
