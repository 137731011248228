import { Component, OnInit } from '@angular/core';
import { TutorialService } from 'src/app/services/tutorial.service';
import { ClientService } from 'src/app/services/client.service';
import { Router  } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  clients: any;
  currentTutorial = null;
  currentIndex = -1;
  title = ''; 
  currentPage = 1;

  constructor(private clientService: ClientService,private router: Router) { }

  ngOnInit() {
    this.retrieveClients(this.currentPage);
  }

  retrieveClients(currentPage) {
    this.clientService.getAll(currentPage,0)
      .subscribe(
        data => {
          this.clients = data;
          console.log(data);
        },
        error => {
          console.log(error);
        });
  }

  createRange(number){
    // var items: number[] = [];
    // for(var i = 1; i <= number; i++){
    //   items.push(i);
    // }
    // return items;
    return new Array(number);
  }

  refreshList() {
    this.retrieveClients(1);
    this.currentTutorial = null;
    this.currentIndex = -1;
  }

  setActiveTutorial(tutorial, index) {
    this.currentTutorial = tutorial;
    this.currentIndex = index;
  }

  removeAllTutorials() {
    this.clientService.deleteAll()
      .subscribe(
        response => {
          console.log(response);
          this.refreshList();
        },
        error => {
          console.log(error);
        });
  }

  searchTitle() {
    this.clientService.findByTitle(this.title)
      .subscribe(
        data => {
          this.clients = data;
          console.log(data);
        },
        error => {
          console.log(error);
        });
  }


  deleteClient(id) {
    if(confirm("Are you sure to delete ??")) {
      this.clientService.delete(id)
        .subscribe(
          response => {
            alert("Client successfully removed");
            window.location.reload();
          },
          error => {
            console.log(error);
          });
    }
  }
}
