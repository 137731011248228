import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders } from '@angular/common/http';

const baseUrl = 'https://nodejspro.btcbazaar.ca/api/client';
const purchaseHistoryBaseUrl = 'https://nodejspro.btcbazaar.ca/api/purchase_history';
const paymentTypeBaseUrl = 'https://nodejspro.btcbazaar.ca/api/payment_type';
const exchangeHistoryBaseUrl = 'https://nodejspro.btcbazaar.ca/api/exchange_history';

@Injectable({
  providedIn: 'root'
})
export class ClientService {

  constructor(private http: HttpClient) { }

  getAll(currentPage,type) {
    var clientbaseUrl = baseUrl+'?page='+currentPage+'&type='+type;
    return this.http.get(clientbaseUrl);
  } 

  get(id) {
    return this.http.get(`${baseUrl}/${id}`);
  }

  create(data) {
    let headers = new HttpHeaders({
      enctype: 'multipart/form-data',
      Accept: 'application/json',
    });
    let options = {
      headers: headers,
    };
    return this.http.post(baseUrl, data, options);
  }

  update(id, data) {
    return this.http.put(`${baseUrl}/${id}`, data);
  }

  delete(id) {
    return this.http.delete(`${baseUrl}/${id}`);
  }

  deleteAll() {
    return this.http.delete(baseUrl);
  }

  findByTitle(title) {
    return this.http.get(`${baseUrl}?title=${title}`);
  }

  
  getAllPurchaseHistory() {
    return this.http.get(purchaseHistoryBaseUrl);
  } 
  
  getAllPaymentType() {
    return this.http.get(paymentTypeBaseUrl);
  } 
  
  getAllExchangeUse() {
    return this.http.get(exchangeHistoryBaseUrl);
  } 
}
