import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { TutorialsListComponent } from './components/tutorials-list/tutorials-list.component';
import { HomeComponent } from './components/home/home.component';
import { NewTransactionComponent } from './components/newtransaction/newtransaction.component';

import { TutorialDetailsComponent } from './components/tutorial-details/tutorial-details.component';
import { AddTutorialComponent } from './components/add-tutorial/add-tutorial.component';
import { BuyComponent } from './components/buy/buy.component';
import { SellComponent } from './components/sell/sell.component';
import { AddClientComponent } from './add-client/add-client.component';
import { ViewTransactionhistoryComponent } from './view-transactionhistory/view-transactionhistory.component';
import { EditClientComponent } from './edit-client/edit-client.component';
import { TransactionHistoryComponent } from './components/transactionhistory/transactionhistory.component';
import { FxTransactionComponent } from './components/fxtransaction/fxtransaction.component';
import { FeeGainComponent } from './components/feegain/feegain.component';
import { OtherFeeComponent } from './components/otherfee/otherfee.component';
import { BalanceComponent } from './components/balance/balance.component';
import { SigninComponent } from './components/signin/signin.component';
import { AuthGuard } from './auth.guard';

const routes: Routes = [
  { path: 'test', redirectTo: 'tutorials', pathMatch: 'full' },
  { path: '', component: HomeComponent ,canActivate: [AuthGuard]  },
  { path: 'add-client', component: AddClientComponent,canActivate: [AuthGuard]   },
  { path: 'edit-client/:id', component: EditClientComponent,canActivate: [AuthGuard]  },
  { path: 'view-transaction-history/:id', component: ViewTransactionhistoryComponent,canActivate: [AuthGuard]  },
  { path: 'buy', component: BuyComponent,canActivate: [AuthGuard]   },
  { path: 'sell', component: SellComponent ,canActivate: [AuthGuard]  },
  { path: 'newtransaction', component: NewTransactionComponent ,canActivate: [AuthGuard]  },
  { path: 'transactionhistory', component: TransactionHistoryComponent ,canActivate: [AuthGuard]  },
  { path: 'fx-transaction', component: FxTransactionComponent ,canActivate: [AuthGuard]  },
  { path: 'feegain', component: FeeGainComponent,canActivate: [AuthGuard]   },
  { path: 'otherfee', component: OtherFeeComponent,canActivate: [AuthGuard]   },
  { path: 'balance', component: BalanceComponent,canActivate: [AuthGuard]   },
  { path: 'tutorials', component: TutorialsListComponent ,canActivate: [AuthGuard] },
  { path: 'tutorials/:id', component: TutorialDetailsComponent,canActivate: [AuthGuard]  },
  { path: 'add', component: AddTutorialComponent,canActivate: [AuthGuard]  },
  {
    path: 'signin',
    component: SigninComponent
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
