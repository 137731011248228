import { Component } from '@angular/core';
import { AuthService } from './services/auth.service';
import {ActivatedRoute, Router,NavigationStart,NavigationEnd, Event as NavigationEvent} from '@angular/router'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
 
export class AppComponent {
  currentUrl: string = 'The title';
  constructor(private authService: AuthService,private router: Router) {
    
    this.router.events.subscribe((e) => {
      if (e instanceof NavigationEnd) {
        console.log(e.url);
        this.currentUrl = e.url;
      }
    });
    

  }

  signOut() {
    localStorage.removeItem('token');
    this.router.navigate(['/']);
  }

  title = 'frontend';
}
