import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule,ReactiveFormsModule  } from '@angular/forms';
import { HttpClientModule,HTTP_INTERCEPTORS } from '@angular/common/http';
import { MatDatepickerModule, MatInputModule, MatNativeDateModule,MatAutocompleteModule } from '@angular/material';


import {WebcamModule} from 'ngx-webcam';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AddTutorialComponent } from './components/add-tutorial/add-tutorial.component';
import { TutorialDetailsComponent } from './components/tutorial-details/tutorial-details.component';
import { TutorialsListComponent } from './components/tutorials-list/tutorials-list.component';
import { HomeComponent } from './components/home/home.component';
import { NewTransactionComponent } from './components/newtransaction/newtransaction.component';
import { BuyComponent } from './components/buy/buy.component';
import { SellComponent } from './components/sell/sell.component';
import { TransactionHistoryComponent } from './components/transactionhistory/transactionhistory.component';
import { FxTransactionComponent } from './components/fxtransaction/fxtransaction.component';
import { FeeGainComponent } from './components/feegain/feegain.component';
import { OtherFeeComponent } from './components/otherfee/otherfee.component';
import { BalanceComponent } from './components/balance/balance.component';
import { SigninComponent } from './components/signin/signin.component';
import { AuthGuard } from './auth.guard';
import { TokenInterceptorService } from './services/token-interceptor.service';
import { AddClientComponent } from './add-client/add-client.component';
import { EditClientComponent } from './edit-client/edit-client.component';
import { NgxScannerQrcodeModule } from 'ngx-scanner-qrcode';
import { ViewTransactionhistoryComponent } from './view-transactionhistory/view-transactionhistory.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {AutocompleteLibModule} from 'angular-ng-autocomplete';

@NgModule({
  declarations: [
    AppComponent,
    AddTutorialComponent,
    TutorialDetailsComponent,
    TutorialsListComponent,
    HomeComponent,
    NewTransactionComponent,
    TransactionHistoryComponent,
    FxTransactionComponent,
    FeeGainComponent,
    OtherFeeComponent,
    BalanceComponent,
    SellComponent,
    SigninComponent,
    BuyComponent,
    AddClientComponent,
    EditClientComponent,
    ViewTransactionhistoryComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    WebcamModule,
    NgxScannerQrcodeModule,
    BrowserAnimationsModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatAutocompleteModule,
    AutocompleteLibModule
  ],
  providers: [AuthGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptorService,
      multi: true
    }],
  bootstrap: [AppComponent]
})
export class AppModule { }
