import { Component, OnInit } from '@angular/core';
import { ActivatedRoute,Router  } from '@angular/router';
import { TransactionHistoryService } from 'src/app/services/transaction_history.service';
import { ClientService } from 'src/app/services/client.service';

import { FormGroup, FormControl, Validators, FormBuilder,ReactiveFormsModule } from '@angular/forms';
@Component({
  selector: 'app-view-transactionhistory',
  templateUrl: './view-transactionhistory.component.html',
  styleUrls: ['./view-transactionhistory.component.css']
})
export class ViewTransactionhistoryComponent implements OnInit {
  buyForm: FormGroup; 
  clients: any;
  purchase_histories: any;
  payment_types: any;
  exchange_uses: any;
  constructor(private clientService: ClientService,private transactionHistoryService: TransactionHistoryService,private route: ActivatedRoute,private router: Router,private fb: FormBuilder) { }

  ngOnInit() {
    this.buyForm = this.fb.group({ 
                          invoice_number: ["", Validators.required], 
                          client_id: ["", Validators.required], 
                          wallet_address: ["", Validators.required], 
                          date_of_purchase: ["", Validators.required], 
                          type: ["sell", Validators.required], 
                          amount_in_cad: ["", Validators.required], 
                          available_crypto_amount: ["", Validators.required], 
                          purchasing_currency_id: ["", Validators.required], 
                          live_rate: ["", Validators.required], 
                          fx_rate: ["", Validators.required], 
                          fee_percentage: ["", Validators.required], 
                          fee_gained: ["", Validators.required],
                          amount_usdt: ["", Validators.required],
                          amount: ["", Validators.required],
                          trading_fee: ["", Validators.required], 
                          network_fee	: ["", Validators.required], 
                          payment_type_id: ["", Validators.required], 
                          exchange_use: ["", Validators.required], 
                          payout_amount: ["", Validators.required], 
                          payout_unit: ["", Validators.required], 
                          other_fees: ["", Validators.required], 
                      }); 

      this.getClient(this.route.snapshot.paramMap.get('id'));
  }


  getClient(id) {
    this.transactionHistoryService.get(id)
      .subscribe(
        (data:any) => {
          // this.currentTutorial = data;
          if(data)
          {
                var setValues = {
                                invoice_number: data.invoice_number, 
                                client_id: data.client_id,  
                                wallet_address: data.wallet_address,
                                date_of_purchase: data.date_of_purchase,
                                type: data.type, 
                                amount_in_cad: data.amount_in_cad, 
                                available_crypto_amount: data.available_crypto_amount, 
                                purchasing_currency_id: data.purchasing_currency_id, 
                                live_rate: data.live_rate, 
                                fx_rate: data.fx_rate, 
                                fee_percentage:data.fee_percentage, 
                                fee_gained: data.fee_gained, 
                                amount_usdt: data.amount_usdt, 
                                amount: data.amount, 
                                trading_fee:data.trading_fee, 
                                network_fee: data.network_fee,
                                payment_type_id: data.payment_type_id,
                                exchange_use: data.exchange_use,
                                payout_amount: data.payout_amount,
                                payout_unit: data.payout_unit,
                                other_fees: data.other_fees
                            };
            console.log(setValues);
            this.buyForm.setValue(setValues);
          }
        },
        error => {
          console.log(error);
        });

     
    
    this.clientService.getAll(1,1)
      .subscribe(
        (data:any) => {
          this.clients = data.data;
          console.log(data);
        },
        error => {
          console.log(error);
        });
    this.clientService.getAllPurchaseHistory()
      .subscribe(
        data => {
          this.purchase_histories = data;
          console.log(data);
        },
        error => {
          console.log(error);
        });
    this.clientService.getAllPaymentType()
      .subscribe(
        data => {
          this.payment_types = data;
          console.log(data);
        },
        error => {
          console.log(error);
        });
    this.clientService.getAllExchangeUse()
      .subscribe(
        data => {
          this.exchange_uses = data;
          console.log(data);
        },
        error => {
          console.log(error);
        });
  }
 
}
